<template>
  <FormModal show-back-btn title="Geschat aantal kozijnen">
    <template #body>
      <EstimatedNumberOfFramesWhatTypeOfFramesFormStep
        :progress-value="progressValue"
      />
    </template>
  </FormModal>
</template>

<script>
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import EstimatedNumberOfFramesWhatTypeOfFramesFormStep from '~/components/form/steps/estimatedNumberOfFramesWhatTypeOfFrames/EstimatedNumberOfFramesWhatTypeOfFramesFormStep'

export default {
  components: {
    EstimatedNumberOfFramesWhatTypeOfFramesFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Geschat aantal kozijnen',
      headDescription:
        'Geef aan hoeveel en wat voor type kozijnen je op zoek bent.',
      path: '/offertes-aanvragen/geschat-aantal-kozijnen-type-kozijnen',
      progressValue: 50,
      checkoutStep: 2,
    }
  },
}
</script>
