<template>
  <FormModal :title="stepTitle">
    <template #body>
      <ServiceFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import { ExperimentSource } from 'chimera/all/mixins/usesExperiments'
import ServiceFormStep from '~/components/form/steps/service/ServiceFormStep'

export default {
  components: {
    ServiceFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Type opdracht',
      headDescription:
        'Geef aan naar wat voor type kozijnen leverancier je op zoek bent.',
      path: '/offertes-aanvragen/type-opdracht',
      progressValue: 25,
      checkoutStep: 1,
      experiment: undefined,
      stepTitle: 'Type aanvraag',
    }
  },

  /**
   *
   * @returns {Promise<void>}
   */
  async beforeMount() {
    // https://bambelo.absmartly.com/experiments/353
    this.experiment = await this.$store.dispatch('experiments/get', {
      id: 'PD-6825',
      source: ExperimentSource.ABSMARTLY,
    })

    if (this.experiment && this.experiment.isTreatment) {
      this.stepTitle = 'Type opdracht'
    }
  },
}
</script>
