<template>
  <ServiceRadioField :choices="serviceSelections" v-on="$listeners" />
</template>

<script>
import { windowFramesPlasticNL } from 'chimera/windowFrames/service'
import { Selectable } from 'chimera/all/components/models/Selectable'
import ServiceRadioField from 'chimera/all/components/form/fields/service/ServiceRadioField.vue'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    ServiceRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      serviceSelections: [
        new Selectable(
          'Kunststof kozijnen',
          'Kunststof kozijnen',
          windowFramesPlasticNL.id,
        ),
        new Selectable(
          'Aluminium kozijnen',
          'Aluminium kozijnen',
          windowFramesPlasticNL.id,
        ),
      ],
    }
  },
}
</script>
