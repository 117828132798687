<template>
  <div>
    <t-form ref="form" @submit.prevent>
      <MarketSegmentRadioFormPart @result="onResult" @change="onChange" />
      <h2 class="form-modal__part-title">
        {{ $t('steps.typeOfAssignment') }}
      </h2>
      <template v-if="experiment && experiment.isTreatment">
        <ServiceRadioFormPartPD6825 @result="onResult" @change="onChange" />
      </template>
      <template v-else-if="experiment">
        <ServiceRadioFormPart @result="onResult" @change="onChange" />
      </template>
    </t-form>

    <FormErrorMessages :error-messages="errorMessages" />
  </div>
</template>

<script>
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import { ExperimentSource } from 'chimera/all/mixins/usesExperiments'
import MarketSegmentRadioFormPart from 'chimera/all/components/form/parts/marketSegment/MarketSegmentRadioFormPart'
import ServiceRadioFormPart from 'chimera/windowFrames/components/form/parts/service/ServiceRadioFormPart.vue'
import ServiceRadioFormPartPD6825 from '~/components/form/parts/service/ServiceRadioFormPart.PD6825'
import NextStep from '~/pages/offertes-aanvragen/geschat-aantal-kozijnen-type-kozijnen'

export default {
  name: 'ServiceTypeFormStep',

  components: {
    MarketSegmentRadioFormPart,
    ServiceRadioFormPart,
    ServiceRadioFormPartPD6825,
    FormErrorMessages,
  },

  extends: AbstractFormStep,

  /**
   * @returns {{experiment: undefined}}
   */
  data() {
    return {
      experiment: undefined,
    }
  },

  /**
   * Pass through the submit event given from FormModal
   */
  created() {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed() {
    this.$nuxt.$off('submit')
  },

  /**
   * @returns {Promise<void>}
   */
  async beforeMount() {
    // https://bambelo.absmartly.com/experiments/353
    this.experiment = await this.$store.dispatch('experiments/get', {
      id: 'PD-6825',
      source: ExperimentSource.ABSMARTLY,
    })
  },

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(NextStep)
    },
  },
}
</script>
