<template>
  <div class="form-row">
    <EstimatedNumberOfFramesField v-on="$listeners" />
  </div>
</template>

<script>
import EstimatedNumberOfFramesField from 'chimera/all/components/form/fields/estimatedNumberOfFrames/EstimatedNumberOfFramesField'

export default {
  name: 'EstimatedNumberOfFramesFormPart',

  components: {
    EstimatedNumberOfFramesField,
  },
}
</script>
