<template>
  <t-text-field
    :id="field"
    v-model="value"
    v-bind="{ ...$attrs }"
    :class="[`field-${field}`]"
    name="Geschat aantal kozijnen"
    :autocomplete="autocomplete"
    :error-messages="errors"
    :placeholder="placeholder"
    :autofocus="autofocus"
    :loading="loading"
    :success="isValid"
    type="number"
    min="0"
    @blur="validate(value)"
  />
</template>

<script>
import AbstractFormField from 'chimera/all/components/form/fields/AbstractFormField'

export const field = 'estimated-number-of-frames'
export default {
  name: 'EstimatedNumberOfFramesField',

  extends: AbstractFormField,

  props: {
    field: {
      type: String,
      default: field,
    },

    placeholder: {
      type: String,
      default: 'Geschat aantal kozijnen',
    },
  },

  data: () => ({
    value: '',
  }),

  watch: {
    /**
     * @param {string} value
     */
    value(value) {
      this.reset()
    },
  },
}
</script>
