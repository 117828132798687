<template>
  <div class="market-segment-form-part">
    <MarketSegmentRadioField :choices="getMarketSegments()" v-on="$listeners" />
  </div>
</template>

<script>
import MarketSegmentRadioField from 'chimera/all/components/form/fields/marketSegment/MarketSegmentRadioField.vue'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'MarketSegmentRadioFormPart',

  components: {
    MarketSegmentRadioField,
  },

  props: {
    choices: {
      type: Array,
      default: null,
    },
  },

  methods: {
    /**
     * @returns {*[]}
     */
    getMarketSegments() {
      return (
        this.choices || [
          new Selectable(
            this.$t('field.marketSegment.consumer'),
            this.$t('field.marketSegment.consumer'),
            this.$t('field.marketSegment.consumer'),
          ),
          new Selectable(
            this.$t('field.marketSegment.corporate'),
            this.$t('field.marketSegment.corporate'),
            this.$t('field.marketSegment.corporateValue'),
          ),
        ]
      )
    },
  },
}
</script>
