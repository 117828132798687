<template>
  <div :class="[`field-${field}`]">
    <TSelectableGroup
      v-model="value"
      :choices="choices"
      :error-messages="errors"
      :multiple="multiple"
    />
    <FormErrorMessages class="my-3" :error-messages="errors" />
  </div>
</template>

<script>
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import AbstractTSelectableGroupFormField from 'chimera/all/components/form/fields/AbstractTSelectableGroupFormField'
import TSelectableGroup from 'chimera/all/components/elements/TSelectableGroup'

export const field = 'type-of-work'
export default {
  name: 'TypeOfWorkField',

  components: {
    TSelectableGroup,
    FormErrorMessages,
  },

  extends: AbstractTSelectableGroupFormField,

  props: {
    field: {
      type: String,
      default: field,
    },

    multiple: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
